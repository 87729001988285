import React, { useState, useEffect } from 'react';
import NurseTaskPercent from './nurse_task_percent';

function StatusNurseWork({ nurseNames, nurseTaskDataDashboard }) {
    const [names, setNames] = useState([]);

    useEffect(() => {
        // props로 받은 nurseNames를 names 상태에 설정합니다.
        setNames(nurseNames);
    }, [nurseNames]); // nurseNames가 변경될 때마다 useEffect가 실행됩니다.

    return (
        <div style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
            height: '100%',
        }}>
            <div className="Vector8" style={{ width: 255, height: 0, left: 0, top: 0, position: 'absolute', border: '0.50px #EEEEEE solid' }}></div>
            <div style={{ width: 255, height: 20 }}>
                <div style={{ left: 0, top: 4, position: 'absolute', color: '#333333', fontSize: 10, fontFamily: 'Pretendard', fontWeight: '400', wordWrap: 'break-word' }}>이름</div>
                <div style={{ left: 53, top: 4, position: 'absolute', color: '#333333', fontSize: 10, fontFamily: 'Pretendard', fontWeight: '400', wordWrap: 'break-word' }}>업무 현황</div>
            </div>
            <div className="Vector9" style={{ width: 255, height: 0, left: 0, top: 20, position: 'absolute', border: '0.50px #EEEEEE solid' }}></div>
            <div style={{ width: 264, height: '100%', left: 0, position: 'absolute' }}>
                <div style={{ width: 264, height: '100%', left: 0, top: 36, position: 'absolute', overflowY: 'auto', overflowX: 'hidden', msOverflowStyle: 'none', WebkitScrollSnapType: { display: 'none'}, scrollbarWidth: 'none', }}>
                    {names.map((name, index) => { 
                        const nurseTaskDataDashboardOne = nurseTaskDataDashboard[index] || {};
                        return (
                            <div key={index} style={{
                                width: '100%',
                                marginBottom: '16px',
                                display: 'flex'
                            }}>
                                <div style={{
                                    width: 54,
                                    height: 18,
                                    color: '#666666',
                                    fontSize: 12,
                                    fontFamily: 'Pretendard',
                                    fontWeight: '400',
                                    wordWrap: 'break-word',
                                    textAlign: 'left'
                                }}>{name}</div>
                                <div style={{ width: 168, height: 18 }}>
                                    <NurseTaskPercent nurseTaskDataDashboardOne={nurseTaskDataDashboardOne}/>
                                </div>
                            </div>
                        );
                    })}
                </div>
            </div>
        </div>
    );
}

export default StatusNurseWork;