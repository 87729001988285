import React, { useState } from 'react';
import { useWindowHeight } from './userwindow'
import PrescriptionCheckGraph from './chart/prescription_check_percent';
import WardWorkload from './chart/ward_workload';
import StatusNurseWork from './chart/status_nurse_work';
import QI from './chart/qi';
import TaskperPatients from './chart/task_per_patients';

function Dashboard({
    nurseNames,
    patients,
    dashboardData,
    allNewPrescriptions,
    checkedPrescriptions_loading,
    patientTaskDataDashboard,
    nurseTaskDataDashboard
}) {
    const height = useWindowHeight();
    const [checkedPrescriptions, setCheckedPrescriptions] = useState(checkedPrescriptions_loading);

    return (
        <div style={{
            width: '100%',
            height: height - 100,
            flex: 1,
            background: '#ffffff',
            overflowX: 'hidden',
            overflowY: 'auto'
        }}>
            <div style={{ width: 100, height: 36, left: 334, top: 40, position: 'fixed', color: '#333333', fontSize: 24, fontFamily: 'Pretendard', fontWeight: '600', wordWrap: 'break-word' }}>대시보드</div>

            <div className="CardQi" style={{ zIndex: '2', width: 328, height: 222, left: 344, top: 129, position: 'absolute', background: '#FCFCFC', borderRadius: 24, overflow: 'hidden' }}>
                <div style={{ zIndex: '1', width: 75, height: 24, left: 32, top: 24, position: 'absolute', color: '#666666', fontSize: 16, fontFamily: 'Pretendard', fontWeight: '600', wordWrap: 'break-word', textAlign: 'start' }}>새로운 처방</div>
                <div className="Frame5" style={{ width: 274, height: 146, left: 32, top: 62, position: 'absolute' }}>
                    <PrescriptionCheckGraph
                        allNewPrescriptions={allNewPrescriptions}
                        checkedPrescriptions={checkedPrescriptions}
                        setCheckedPrescriptions={setCheckedPrescriptions}
                    />
                </div>
            </div>
            <div className="CardQi" style={{ width: 328, height: 261, left: 1048, top: 575, position: 'absolute', background: '#FCFCFC', borderRadius: 24, overflow: 'hidden' }}>
                <div style={{ left: 32, top: 26, position: 'absolute', color: '#666666', fontSize: 16, fontFamily: 'Pretendard', fontWeight: '600', wordWrap: 'break-word' }}>병동 업무량</div>
                <div style={{ left: 32, top: 64, position: 'absolute', width: 290, height: 197 }}>
                    <WardWorkload />
                </div>
            </div>
            <div className="CardQi" style={{ width: 328, height: 222, left: 695, top: 129, position: 'absolute', background: '#FCFCFC', borderRadius: 24, overflow: 'hidden' }}>
                <div style={{ left: 30, top: 26, position: 'absolute', color: '#666666', fontSize: 14, fontFamily: 'Pretendard', fontWeight: '600', wordWrap: 'break-word' }}>요주의 환자</div>
                <div className="Frame26" style={{ width: '256px', height: '100px', left: 36, top: 80, position: 'absolute', justifyContent: 'flex-start', alignItems: 'flex-start', gap: 8, display: 'inline-flex' }}>
                    <div className="Frame17" style={{ width: 80, height: 100, position: 'relative', background: 'white', boxShadow: '0px 3.5px 5.5px rgba(0, 0, 0, 0.02)', borderRadius: 8, overflow: 'hidden' }}>
                        <div style={{ left: 28, top: 16, position: 'absolute', color: '#666666', fontSize: 10, fontFamily: 'Pretendard', fontWeight: '400', wordWrap: 'break-word' }}>802호</div>
                        <div style={{ left: 25, top: 28, position: 'absolute', color: '#333333', fontSize: 12, fontFamily: 'Pretendard', fontWeight: '600', wordWrap: 'break-word' }}>김신혜</div>
                        <div style={{ left: 19, top: 62, position: 'absolute', color: '#333333', fontSize: 12, fontFamily: 'Pretendard', fontWeight: '600', wordWrap: 'break-word' }}>욕창위험</div>
                    </div>
                    <div className="Frame18" style={{ width: 80, height: 100, position: 'relative', background: '#5499FF', boxShadow: '0px 3.5px 5.5px rgba(84, 153, 255, 0.25)', borderRadius: 8, overflow: 'hidden' }}>
                        <div style={{ left: 28, top: 16, position: 'absolute', color: '#ffffff', fontSize: 10, fontFamily: 'Pretendard', fontWeight: '400', wordWrap: 'break-word' }}>802호</div>
                        <div style={{ left: 25, top: 28, position: 'absolute', color: '#ffffff', fontSize: 12, fontFamily: 'Pretendard', fontWeight: '600', wordWrap: 'break-word' }}>이수진</div>
                        <div style={{ left: 19, top: 62, position: 'absolute', color: '#ffffff', fontSize: 12, fontFamily: 'Pretendard', fontWeight: '600', wordWrap: 'break-word' }}>낙상위험</div>
                    </div>
                    <div className="Frame19" style={{ width: 80, height: 100, position: 'relative', background: 'white', boxShadow: '0px 3.5px 5.5px rgba(0, 0, 0, 0.02)', borderRadius: 8, overflow: 'hidden' }}>
                        <div style={{ left: 28, top: 16, position: 'absolute', color: '#666666', fontSize: 10, fontFamily: 'Pretendard', fontWeight: '400', wordWrap: 'break-word' }}>807호</div>
                        <div style={{ left: 25, top: 28, position: 'absolute', color: '#333333', fontSize: 12, fontFamily: 'Pretendard', fontWeight: '600', wordWrap: 'break-word' }}>윤지호</div>
                        <div style={{ left: 19, top: 62, position: 'absolute', color: '#333333', fontSize: 12, fontFamily: 'Pretendard', fontWeight: '600', wordWrap: 'break-word' }}>낙상위험</div>
                    </div>
                </div>
            </div>
            <div className="CardQi" style={{ width: 328, height: 423, left: 1048, top: 128, position: 'absolute', background: '#FCFCFC', borderRadius: 24, overflow: 'hidden' }}>
                <div style={{ left: 32, top: 24, position: 'absolute', color: '#666666', fontSize: 16, fontFamily: 'Pretendard', fontWeight: '600', wordWrap: 'break-word' }}>간호사별 업무 진행도</div>
                <div className="Frame44" style={{ right: 48, top: 25, position: 'absolute', width: 25, height: 21, paddingLeft: 8, paddingRight: 8, borderRadius: 100, border: '1px #34C759 solid', justifyContent: 'center', alignItems: 'center', gap: 10, display: 'inline-flex' }}>
                    <div className="Day" style={{ color: '#333333', fontSize: 14, fontFamily: 'Pretendard', fontWeight: '600', wordWrap: 'break-word' }}>Day</div>
                </div>
                <div style={{ left: 33, top: 69, position: 'absolute', width: 255, height: 340 }}>
                    <StatusNurseWork nurseNames={nurseNames} nurseTaskDataDashboard={nurseTaskDataDashboard}/>
                </div>
            </div>
            <div className="Frame12" style={{ width: 680, height: 178, left: 344, top: 373, position: 'absolute', background: '#FCFCFC', borderRadius: 24, flexDirection: 'column', justifyContent: 'center', alignItems: 'center', gap: 16, display: 'inline-flex' }}>
                <div className="Frame13" style={{ left: 32, top: 24, position: 'absolute', width: 200, justifyContent: 'flex-start', alignItems: 'flex-start', display: 'inline-flex' }}>
                    <div className="Qi" style={{ color: '#666666', fontSize: 16, fontFamily: 'Pretendard', fontWeight: '600', wordWrap: 'break-word' }}>QI 지표</div>
                </div>
                <div className="Frame11" style={{ width: 616, height: 90, left: 32, top: 64, position: 'absolute', justifyContent: 'flex-start', alignItems: 'flex-start', display: 'inline-flex' }}>
                    <QI dashboardData={dashboardData} />
                </div>
            </div>
            <div className="CardQi" style={{ width: '680px ', height: '261px', position: 'absolute', left: 344, top: 576, background: '#FCFCFC', borderRadius: 24, overflow: 'hidden' }}>
                <div style={{ left: 30, top: 24, position: 'absolute', color: '#666666', fontSize: 16, fontFamily: 'Pretendard', fontWeight: '600', wordWrap: 'break-word' }}>환자별 업무량</div>
                <div style={{ width: 600, height: 197, left: 40, top: 48, position: 'absolute' }}>
                    <TaskperPatients patients={patients} patientTaskDataDashboard={patientTaskDataDashboard} />
                </div>
            </div>
            {/*            
*/}
            <div className="Frame3" style={{ width: 214, height: 48, left: 672, top: 24, position: 'absolute' }} />
        </div>
    );
}


export default Dashboard;
