import React from 'react';
import PatientTaskPercent from './patient_task_percent';

function TaskperPatients({ patients, patientTaskDataDashboard }) {

    return (
        <div style={{ width: '100%', height: '100%' }}>
            <div className="Frame16" style={{ right: 45, top: -19, position: 'absolute', justifyContent: 'flex-start', alignItems: 'flex-start', display: 'inline-flex' }}>
                <div className="Group16" style={{ width: 34, position: 'relative' }}>
                    <div className="Ellipse13" style={{ width: 12, height: 12, left: 0, top: 0, position: 'absolute', background: '#EEF5FF', borderRadius: 9999 }} />
                    <div style={{ left: 16, top: 0, position: 'absolute', color: 'black', fontSize: 10, fontFamily: 'Pretendard', fontWeight: '400', wordWrap: 'break-word' }}>전체</div>
                </div>
                <div className="Group17" style={{ width: 34, position: 'relative' }}>
                    <div className="Ellipse14" style={{ width: 12, height: 12, left: 0, top: 0, position: 'absolute', background: '#5499FF', borderRadius: 9999 }} />
                    <div style={{ left: 16, top: 0, position: 'absolute', color: 'black', fontSize: 10, fontFamily: 'Pretendard', fontWeight: '400', wordWrap: 'break-word' }}>완료</div>
                </div>
            </div>
            <div className='Frame39' style={{ width: 600, height: 197, position: 'absolute', left: 0, top: 0 }}>
                <div style={{ width: 600, height: 197, position: 'absolute', left: 0, top: 0 }}>
                    <div style={{ left: '-12px', top: 14, position: 'absolute', textAlign: 'center', color: '#AAAAAA', fontSize: 8, fontFamily: 'Pretendard', fontWeight: '400', wordWrap: 'break-word' }}>20</div>
                    <div className="Vector12" style={{ width: 594, height: 0, border: '0.50px #EEEEEE solid', left: 3, top: 17, position: 'absolute' }} />
                    <div style={{ left: '-12px', top: 54, position: 'absolute', textAlign: 'center', color: '#AAAAAA', fontSize: 8, fontFamily: 'Pretendard', fontWeight: '400', wordWrap: 'break-word' }}>15</div>
                    <div className="Vector12" style={{ width: 594, height: 0, border: '0.50px #EEEEEE solid', left: 3, top: 57, position: 'absolute' }} />
                    <div style={{ left: '-12px', top: 94, position: 'absolute', textAlign: 'center', color: '#AAAAAA', fontSize: 8, fontFamily: 'Pretendard', fontWeight: '400', wordWrap: 'break-word' }}>10</div>
                    <div className="Vector12" style={{ width: 594, height: 0, border: '0.50px #EEEEEE solid', left: 3, top: 97, position: 'absolute' }} />
                    <div style={{ left: '-12px', top: 134, position: 'absolute', textAlign: 'center', color: '#AAAAAA', fontSize: 8, fontFamily: 'Pretendard', fontWeight: '400', wordWrap: 'break-word' }}>5</div>
                    <div className="Vector12" style={{ width: 594, height: 0, border: '0.50px #EEEEEE solid', left: 3, top: 137, position: 'absolute' }} />
                    <div className="Vector12" style={{ width: 594, height: 0, border: '0.50px #EEEEEE solid', left: 3, top: 177, position: 'absolute' }} />
                </div>
                <div style={{
                    width: 600,
                    height: 177,
                    position: 'absolute',
                    bottom: 0,
                    overflowX: 'auto',
                    overflowY: 'hidden',
                    display: 'flex' // flexbox를 사용하여 가로 방향으로 요소들을 나열합니다.
                }}>
                    {patients.map((patient, index) => {
                        const patientTaskDataDashboardOne = patientTaskDataDashboard[index] || {};
                        return (
                            <div key={index} style={{
                                width: 32,
                                height: 177,
                                display: 'flex',
                                flexDirection: 'column', // 세로 방향으로 내부 요소들을 정렬합니다.
                                alignItems: 'center', // 가로 중심으로 내부 요소들을 정렬합니다.
                                marginRight: 10 // 요소들 사이에 오른쪽 여백을 추가합니다.
                            }}>
                                <div style={{
                                    width: 32,
                                    height: 12,
                                    color: '#333333',
                                    fontSize: 10,
                                    fontFamily: 'Pretendard',
                                    fontWeight: '400',
                                    wordWrap: 'break-word',
                                    textAlign: 'center',
                                    position: 'absolute',
                                    bottom: 0,
                                }}>
                                    {patient.name}
                                </div>
                                <div style={{ width: 32, height: 177 }}>
                                    <PatientTaskPercent patientTaskDataDashboardOne={patientTaskDataDashboardOne} />
                                </div>
                            </div>
                        );
                    })}
                </div>

            </div>
        </div>
    );
}

export default TaskperPatients;
