import React from 'react';

function QIList({ dashboardData }) {
    const dashboardItem = dashboardData[0] || {};

    const {
        painRate = 0, // 기본값 설정
        reaskedRate = 0,
        bradenReports = 0,
        nurseCallsMetrics = 0,
        fallReports = 0,
        codeBlues = 0,
        codeReds = 0,
        codeYellows = 0,
        syringeHurtReports = 0,
    } = dashboardItem;


    return (
        <div style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: 440,
            height: 602,
            marginTop: 12
        }}>
            <div className="Frame47" style={{ width: 240, height: 159, left: 0, top: 0, position: 'absolute', background: '#FCFCFC', borderRadius: 16, overflow: 'hidden' }}>
                <div style={{ left: 32, top: 24, position: 'absolute', color: '#666666', fontSize: 16, fontFamily: 'Pretendard', fontWeight: '600', wordWrap: 'break-word' }}>환자통증평가율</div>
                <div style={{ left: 32, top: 56, position: 'absolute', color: '#333333', fontSize: 48, fontFamily: 'Pretendard', fontWeight: '700', wordWrap: 'break-word' }}>{painRate}%</div>
                <div className="Frame86" style={{ width: 27, height: 18, paddingLeft: 4, paddingRight: 4, paddingTop: 2, paddingBottom: 2, left: 177, top: 25, position: 'absolute', background: '#5DD27A', borderRadius: 100, justifyContent: 'center', alignItems: 'center', display: 'inline-flex' }}>
                    <div className="5" style={{ color: 'white', fontSize: 12, fontFamily: 'Pretendard', fontWeight: '600', wordWrap: 'break-word' }}>+5%</div>
                </div>
            </div>
            <div className="Frame52" style={{ width: 240, height: 159, left: 264, top: 0, position: 'absolute', background: '#FCFCFC', borderRadius: 16, overflow: 'hidden' }}>
                <div style={{ left: 32, top: 24, position: 'absolute', color: '#666666', fontSize: 16, fontFamily: 'Pretendard', fontWeight: '600', wordWrap: 'break-word' }}>타과 진료의뢰 회신율</div>
                <div style={{ left: 32, top: 56, position: 'absolute', color: '#333333', fontSize: 48, fontFamily: 'Pretendard', fontWeight: '700', wordWrap: 'break-word' }}>{reaskedRate}%</div>
                <div className="Frame86" style={{ width: 27, height: 18, paddingLeft: 4, paddingRight: 4, paddingTop: 2, paddingBottom: 2, left: 177, top: 25, position: 'absolute', background: '#5DD27A', borderRadius: 100, justifyContent: 'center', alignItems: 'center', display: 'inline-flex' }}>
                    <div className="5" style={{ color: 'white', fontSize: 12, fontFamily: 'Pretendard', fontWeight: '600', wordWrap: 'break-word' }}>+5%</div>
                </div>
            </div>
            <div className="Frame48" style={{ width: 240, height: 159, left: 0, top: 183, position: 'absolute', background: '#FCFCFC', borderRadius: 16, overflow: 'hidden' }}>
                <div style={{ left: 32, top: 24, position: 'absolute', color: '#666666', fontSize: 16, fontFamily: 'Pretendard', fontWeight: '600', wordWrap: 'break-word' }}>욕창발생보고</div>
                <div style={{ left: 32, top: 56, position: 'absolute', color: '#333333', fontSize: 48, fontFamily: 'Pretendard', fontWeight: '700', wordWrap: 'break-word' }}>{bradenReports}</div>
                <div className="Frame86" style={{ width: 27, height: 18, paddingLeft: 4, paddingRight: 4, paddingTop: 2, paddingBottom: 2, left: 177, top: 25, position: 'absolute', background: '#FF4C4C', borderRadius: 100, justifyContent: 'center', alignItems: 'center', display: 'inline-flex' }}>
                    <div className="1" style={{ textAlign: 'center', color: 'white', fontSize: 12, fontFamily: 'Pretendard', fontWeight: '600', wordWrap: 'break-word' }}>+1</div>
                </div>
            </div>
            <div className="Frame41" style={{ width: 240, height: 159, left: 264, top: 183, position: 'absolute', background: '#FCFCFC', borderRadius: 16, overflow: 'hidden' }}>
                <div style={{ left: 32, top: 24, position: 'absolute', color: '#666666', fontSize: 16, fontFamily: 'Pretendard', fontWeight: '600', wordWrap: 'break-word' }}>간호사호출빈도</div>
                <div style={{ left: 32, top: 56, position: 'absolute', color: '#333333', fontSize: 48, fontFamily: 'Pretendard', fontWeight: '700', wordWrap: 'break-word' }}>{nurseCallsMetrics}</div>
                <div className="Frame86" style={{ width: 27, height: 18, paddingLeft: 4, paddingRight: 4, paddingTop: 2, paddingBottom: 2, left: 177, top: 25, position: 'absolute', background: '#FF4C4C', borderRadius: 100, justifyContent: 'center', alignItems: 'center', display: 'inline-flex' }}>
                    <div className="1" style={{ textAlign: 'center', color: 'white', fontSize: 12, fontFamily: 'Pretendard', fontWeight: '600', wordWrap: 'break-word' }}>+1</div>
                </div>
            </div>
            <div className="Frame44" style={{ width: 240, height: 159, left: 0, top: 366, position: 'absolute', background: '#FCFCFC', borderRadius: 16, overflow: 'hidden' }}>
                <div style={{ left: 32, top: 24, position: 'absolute', color: '#666666', fontSize: 16, fontFamily: 'Pretendard', fontWeight: '600', wordWrap: 'break-word' }}>낙상발생보고</div>
                <div style={{ left: 32, top: 56, position: 'absolute', color: '#333333', fontSize: 48, fontFamily: 'Pretendard', fontWeight: '700', wordWrap: 'break-word' }}>{fallReports}</div>
                <div className="Frame86" style={{ width: 27, height: 18, paddingLeft: 4, paddingRight: 4, paddingTop: 2, paddingBottom: 2, left: 177, top: 25, position: 'absolute', background: '#FF4C4C', borderRadius: 100, justifyContent: 'center', alignItems: 'center', display: 'inline-flex' }}>
                    <div className="1" style={{ textAlign: 'center', color: 'white', fontSize: 12, fontFamily: 'Pretendard', fontWeight: '600', wordWrap: 'break-word' }}>+1</div>
                </div>
            </div>
            <div className="Frame49" style={{ width: 240, height: 159, left: 0, top: 549, position: 'absolute', background: '#FCFCFC', borderRadius: 16, overflow: 'hidden' }}>
                <div style={{ left: 32, top: 24, position: 'absolute', color: '#666666', fontSize: 16, fontFamily: 'Pretendard', fontWeight: '600', wordWrap: 'break-word' }}>주사침자상사고보고</div>
                <div style={{ left: 32, top: 56, position: 'absolute', color: '#333333', fontSize: 48, fontFamily: 'Pretendard', fontWeight: '700', wordWrap: 'break-word' }}>{syringeHurtReports}</div>
                <div className="Frame86" style={{ width: 27, height: 18, paddingLeft: 4, paddingRight: 4, paddingTop: 2, paddingBottom: 2, left: 177, top: 25, position: 'absolute', background: '#FF4C4C', borderRadius: 100, justifyContent: 'center', alignItems: 'center', display: 'inline-flex' }}>
                    <div className="1" style={{ textAlign: 'center', color: 'white', fontSize: 12, fontFamily: 'Pretendard', fontWeight: '600', wordWrap: 'break-word' }}>+1</div>
                </div>
            </div>
            <div className="Frame51" style={{ width: 240, height: 342, left: 264, top: 366, position: 'absolute', background: '#FCFCFC', borderRadius: 16, overflow: 'hidden' }}>
                <div style={{ left: 32, top: 24, position: 'absolute', color: '#666666', fontSize: 16, fontFamily: 'Pretendard', fontWeight: '600', wordWrap: 'break-word' }}>응급상황발생횟수</div>
                <div style={{ left: 32, top: 56, position: 'absolute', color: '#333333', fontSize: 48, fontFamily: 'Pretendard', fontWeight: '700', wordWrap: 'break-word' }}>{codeBlues+codeYellows+codeReds}</div>
                <div className="Frame83" style={{ width: 176, height: 144, left: 32, top: 150, position: 'absolute', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', gap: 24, display: 'inline-flex' }}>
                    <div className="Frame80" style={{ width: '100%', height: 32, alignSelf: 'stretch', borderTopLeftRadius: 20, borderTopRightRadius: 12, borderBottomRightRadius: 12, borderBottomLeftRadius: 12, justifyContent: 'space-between', alignItems: 'center', display: 'inline-flex' }}>
                        <div className="Frame78" style={{ height: 32, justifyContent: 'flex-start', alignItems: 'flex-start', gap: 8, display: 'flex' }}>
                            <div className="Rectangle98" style={{ width: 4, height: 32, background: '#76ADFF', borderRadius: 100 }} />
                            <div className="Frame75" style={{ flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', display: 'inline-flex' }}>
                                <div className="CodeBlue" style={{ color: '#323232', fontSize: 12, fontFamily: 'Pretendard', fontWeight: '600', wordWrap: 'break-word' }}>Code Blue</div>
                                <div style={{ color: '#777777', fontSize: 10, fontFamily: 'Pretendard', fontWeight: '400', wordWrap: 'break-word' }}>심장마비 발생</div>
                            </div>
                        </div>
                        <div style={{ right: 0, top: 4, color: '#333333', fontSize: 16, fontFamily: 'Pretendard', fontWeight: '600', wordWrap: 'break-word' }}>{codeBlues}</div>
                    </div>
                    <div className="Frame81" style={{ width: '100%', height: 32, alignSelf: 'stretch', borderTopLeftRadius: 20, borderTopRightRadius: 12, borderBottomRightRadius: 12, borderBottomLeftRadius: 12, justifyContent: 'space-between', alignItems: 'center', display: 'inline-flex' }}>
                        <div className="Frame79" style={{ height: 32, justifyContent: 'flex-start', alignItems: 'flex-start', gap: 8, display: 'flex' }}>
                            <div className="Rectangle98" style={{ width: 4, height: 32, background: '#FFEB33', borderRadius: 100 }} />
                            <div className="Frame75" style={{ flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', display: 'inline-flex' }}>
                                <div className="CodeYellow" style={{ color: '#323232', fontSize: 12, fontFamily: 'Pretendard', fontWeight: '600', wordWrap: 'break-word' }}>Code Yellow</div>
                                <div style={{ color: '#777777', fontSize: 10, fontFamily: 'Pretendard', fontWeight: '400', wordWrap: 'break-word' }}>환자누락, 유실</div>
                            </div>
                        </div>
                        <div style={{ right: 0, top: 4, color: '#333333', fontSize: 16, fontFamily: 'Pretendard', fontWeight: '600', wordWrap: 'break-word' }}>{codeYellows}</div>
                    </div>
                    <div className="Frame82" style={{ width: '100%', height: 32, alignSelf: 'stretch', borderTopLeftRadius: 20, borderTopRightRadius: 12, borderBottomRightRadius: 12, borderBottomLeftRadius: 12, justifyContent: 'space-between', alignItems: 'center', display: 'inline-flex' }}>
                        <div className="Frame76" style={{ height: 32, justifyContent: 'flex-start', alignItems: 'flex-start', gap: 8, display: 'flex' }}>
                            <div className="Rectangle98" style={{ width: 4, height: 32, background: '#FF4C4C', borderRadius: 100 }} />
                            <div className="Frame75" style={{ flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', display: 'inline-flex' }}>
                                <div className="CodeRed" style={{ color: '#323232', fontSize: 12, fontFamily: 'Pretendard', fontWeight: '600', wordWrap: 'break-word' }}>Code Red</div>
                                <div style={{ color: '#777777', fontSize: 10, fontFamily: 'Pretendard', fontWeight: '400', wordWrap: 'break-word' }}>화재발생</div>
                            </div>
                        </div>
                        <div style={{ right: 0, top: 4, color: '#333333', fontSize: 16, fontFamily: 'Pretendard', fontWeight: '600', wordWrap: 'break-word' }}>{codeReds}</div>
                    </div>
                </div>
                <div className="Frame86" style={{ width: 27, height: 18, paddingLeft: 4, paddingRight: 4, paddingTop: 2, paddingBottom: 2, left: 177, top: 25, position: 'absolute', background: '#FF4C4C', borderRadius: 100, justifyContent: 'center', alignItems: 'center', display: 'inline-flex' }}>
                    <div className="1" style={{ textAlign: 'center', color: 'white', fontSize: 12, fontFamily: 'Pretendard', fontWeight: '600', wordWrap: 'break-word' }}>+1</div>
                </div>
            </div>
        </div>
    );
}

export default QIList;
