import React, { useState, useEffect } from 'react';
import { doc, getDoc, onSnapshot } from "firebase/firestore";
import { dbService } from './firebase';
import { useWindowWidth } from './userwindow';

function LoginScreen({ onLoginSuccess }) { // 구조 분해 할당을 사용하여 props에서 onLoginSuccess 추출
    const width = useWindowWidth();
    const [nurseID, setNurseID] = useState('');
    const [password, setPassword] = useState('');
    const [isLoggedIn, setIsLoggedIn] = useState(false);

    useEffect(() => {
        if (isLoggedIn) {
            // 사용자가 로그인하면 Firestore에서 간호사 정보에 대한 실시간 업데이트를 받습니다.
            const unsubscribe = onSnapshot(doc(dbService, 'NurSync.Nurses', nurseID), (doc) => {
                if (doc.exists() && doc.data().password === password) {
                    // 사용자가 로그인 상태를 유지하고 있습니다.
                } else {
                    // 비밀번호가 변경되었거나 사용자 문서가 존재하지 않으면 로그아웃 처리합니다.
                    setIsLoggedIn(false);
                    // 여기서 로그아웃 처리 함수를 호출할 수 있습니다. 예: onLogout()
                }
            });

            // 리스너를 해제합니다.
            return () => unsubscribe();
        }
    }, [isLoggedIn, nurseID, password]);

    const handleLogin = async () => {
        try {
            const nurseRef = doc(dbService, 'NurSync.Nurses', nurseID);
            const nurseDoc = await getDoc(nurseRef);

            if (nurseDoc.exists() && nurseDoc.data().password === password) {
                setIsLoggedIn(true);
                onLoginSuccess(); // 로그인 성공 처리를 합니다.
            } else {
                alert('아이디 혹은 비밀번호가 틀렸습니다.');
                setIsLoggedIn(false);
            }
        } catch (error) {
            alert('로그인 처리 중 오류가 발생했습니다.');
            setIsLoggedIn(false);
        }
    };


    // 'Enter' 키 입력을 처리하기 위한 함수
    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            handleLogin();
        }
    };

    if (!isLoggedIn) {
        return (
            <div className="adminpage_LogIn">
                <div className="Rectangle55" style={{ width: width, height: 804, position: 'relative', background: 'linear-gradient(270deg, #EEF5FF 0%, rgba(238, 245, 255, 0) 100%)' }}>
                    <div className="adminpage_Nursync_Logo" style={{ left: 203, top: 266, position: 'absolute' }}>NurSync</div>
                    <div className="adminpage_adminText" style={{ left: 363, top: 288, position: 'absolute' }}>관리자용</div>

                    <div className="adminpage_textInputCommon" style={{ width: (345/ 1440) * width, left: 196, top: 393 }}>
                        <input type="text" placeholder="간호사 ID" value={nurseID} onChange={(e) => setNurseID(e.target.value)} onKeyPress={handleKeyPress} style={{ flex: 1, border: 'none', outline: 'none', background: 'transparent', color: '#999999', fontSize: '16px', fontFamily: 'Pretendard', paddingLeft: 10 }} />
                    </div>

                    <div className="adminpage_textInputCommon" style={{ width: (345/ 1440) * width, left: 196, top: 490 }}>
                        <input type="password" placeholder="비밀번호" value={password} onChange={(e) => setPassword(e.target.value)} onKeyPress={handleKeyPress} style={{ flex: 1, border: 'none', outline: 'none', background: 'transparent', color: '#999999', fontSize: '16px', fontFamily: 'Pretendard', paddingLeft: 10 }} />
                    </div>

                    <div className="adminpage_textInputCommon adminpage_textInputLogin" onClick={handleLogin} style={{ cursor: 'pointer', width: (345 / 1440) * width, left: 196 , top: 586 }}>
                        <div style={{ color: '#ffffff', fontSize: 16, fontFamily: 'Pretendard', fontWeight: '600', wordWrap: 'break-word'  }}>로그인</div>
                    </div>

                    <div className="adminpage_infoText" style={{ left: 208 , top: 368 }}>직원번호</div>
                    <div className="adminpage_infoText" style={{ left: 208 , top: 465 }}>비밀번호</div>
                </div>
                {/* 저작권 표시 부분을 Rectangle55 아래에 위치하도록 조정 */}
                <div style={{ marginTop: '20px', color: '#D5D5D5', fontSize: 12, fontFamily: 'Pretendard', fontWeight: '400', wordWrap: 'break-word', textAlign: 'center' }}>
                    Copyright © 2023 Room 821 Inc. all rights reserved.
                </div>
            </div>
        );
    }
}

export default LoginScreen;
