import React from 'react';

function PatientList({ patients }) {


    return (
        <div style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: 440,
            height: 602,
            marginTop: 12
        }}>
            <div style={{ width: 440, height: 614, position: 'absolute', left: 0, top: 12, overflowY: 'auto', overflowX: 'hidden' }}>
                {patients.map((patient, index) => (
                    <div key={index} style={{
                        maxWidth: 440,
                        height: 18,
                        display: 'flex',
                        marginBottom: '12px',
                        alignItems: 'center',
                        textAlign: 'start'
                    }}>
                        <div style={{ maxWidth: 440, height: 18, display: 'flex', alignItems: 'center', position: 'relative' }}>
                            <div style={{ left: 0, top: 0, position: 'absolute', width: 72, height: 18, color: '#333333', fontSize: 12, fontFamily: 'Pretendard', fontWeight: '400', wordWrap: 'break-word' }}>{patient.id}</div>
                            <div style={{ left: 72, top: 0 , position: 'absolute', width: 50, height: 18, color: '#333333', fontSize: 12, fontFamily: 'Pretendard', fontWeight: '400', wordWrap: 'break-word' }}>{patient.name}</div>
                            <div className="M92" style={{ left: 122, top: 0 , position: 'absolute', width: 54, height: 18, color: '#333333', fontSize: 12, fontFamily: 'Pretendard', fontWeight: '400', wordWrap: 'break-word' }}>{patient.sex === 0 ? '남' : '여'}/{patient.age}</div>
                            <div className="1230" style={{ left: 176, top: 0 , position: 'absolute', width: 91, height: 18,color: '#333333', fontSize: 12, fontFamily: 'Pretendard', fontWeight: '400', wordWrap: 'break-word' }}>{patient.birthdate}</div>
                            <div className="1" style={{ left: 267 , top: 0 , position: 'absolute',  width: 50, height: 18, color: '#333333', fontSize: 12, fontFamily: 'Pretendard', fontWeight: '400', wordWrap: 'break-word' }}>{patient.ward.split('-')[0]}/{patient.ward.split('-')[1]}</div>
                            <div style={{ left: 335, top: 0 , position: 'absolute', color: '#333333', width: 50, height: 18, fontSize: 12, fontFamily: 'Pretendard', fontWeight: '400', wordWrap: 'break-word' }}>{patient.hod}</div>
                            <div style={{ left: 381, top: 0 , position: 'absolute', color: '#333333', width: 60, height: 18, fontSize: 12, fontFamily: 'Pretendard', fontWeight: '400', wordWrap: 'break-word' }}>{patient.pod}</div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
}

export default PatientList;
