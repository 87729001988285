import React, { useState } from 'react';

function PatientTaskList({ patients }) {
    const [clickedIndex, setClickedIndex] = useState(null); // 클릭된 항목 인덱스를 추적합니다.

    // 항목 클릭 핸들러
    const handleItemClick = (index) => {
        setClickedIndex(index); // 클릭된 항목의 인덱스를 설정합니다.
    };

    return (
        <div style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: 290,
            height: 504
        }}>
            <div style={{ width: 280, height: 504, position: 'absolute', left: 0, overflowY: 'auto',overflowX: 'hidden' }}>
                {patients.map((patient, index) => (
                    <div key={index} onClick={() => handleItemClick(index)} style={{
                        maxWidth: 262,
                        height: 50,
                        marginBottom: '10px',
                        background: '#ffffff',
                        borderRadius: 12,
                        border: clickedIndex === index ? '1px #5499FF solid' : '1px #EEEEEE solid',
                        display: 'flex',
                        alignItems: 'center',
                        paddingLeft: 16,
                    }}>
                        <div style={{
                            marginRight: 16,
                            fontSize: 12,
                            fontFamily: 'Pretendard',
                            fontWeight: '500',
                            color: '#333333'
                        }}>
                            {patient.name}
                        </div>
                        <div className="M74" style={{
                            color: '#C0C0C0',
                            fontSize: 12,
                            fontFamily: 'Pretendard',
                            fontWeight: '400',
                            wordWrap: 'break-word'
                        }}>
                            {patient.sex === 0 ? '남' : '여'}/{patient.age}
                        </div>
                        <div className="Frame64" style={{ 
                            paddingLeft: 112, 
                            paddingRight: 8, 
                            justifyContent: 'center', 
                            alignItems: 'center', 
                            display: 'flex' 
                            }}>
                            <div style={{ color: '#333333', fontSize: 12, fontFamily: 'Pretendard', fontWeight: '400', wordWrap: 'break-word' }}>{patient.ward.split('-')[0]}호</div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
}

export default PatientTaskList;
