import React from 'react';

function QI({ dashboardData }) {
    const dashboardItem = dashboardData[0] || {};

    const {
        painRate = 0, // 기본값 설정
        syringeHurtReports = 0,
        fallReports = 0,
        nurseCallsMetrics = 0,
    } = dashboardItem;

    return (
        <div style={{ position: 'relative' }}>
            <div className="Frame7" style={{ width: 100, padding: 16, background: '#FFFFFF', borderRadius: 16, border: '1px #CADFFF solid', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', gap: 4, display: 'inline-flex' }}>
                        <div className="Frame6" style={{ justifyContent: 'center', alignItems: 'center', gap: 5, display: 'inline-flex' }}>
                            <div style={{ color: 'black', fontSize: 12, fontFamily: 'Pretendard', fontWeight: '400', wordWrap: 'break-word' }}>환자통증평가율</div>
                        </div>
                        <div style={{ color: 'black', fontSize: 24, fontFamily: 'Pretendard', fontWeight: '700', wordWrap: 'break-word' }}>{painRate}%</div>
                    </div>
                    <div className="Frame9" style={{ width: 100, padding: 16, background: '#FFFFFF', borderRadius: 16, border: '1px #CADFFF solid', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', gap: 4, display: 'inline-flex', marginLeft: 20 }}>
                        <div className="Frame6" style={{ justifyContent: 'center', alignItems: 'center', gap: 5, display: 'inline-flex' }}>
                            <div style={{ color: 'black', fontSize: 12, fontFamily: 'Pretendard', fontWeight: '400', wordWrap: 'break-word' }}>욕창발생보고율</div>
                        </div>
                        <div style={{ color: 'black', fontSize: 24, fontFamily: 'Pretendard', fontWeight: '700', wordWrap: 'break-word' }}>{syringeHurtReports}개</div>
                    </div>
                    <div className="Frame10" style={{ width: 100, padding: 16, background: '#FFFFFF', borderRadius: 16, border: '1px #CADFFF solid', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', gap: 4, display: 'inline-flex', marginLeft: 20 }}>
                        <div className="Frame6" style={{ justifyContent: 'center', alignItems: 'center', gap: 5, display: 'inline-flex' }}>
                            <div style={{ color: 'black', fontSize: 12, fontFamily: 'Pretendard', fontWeight: '400', wordWrap: 'break-word' }}>낙상발생보고율</div>
                        </div>
                        <div style={{ color: 'black', fontSize: 24, fontFamily: 'Pretendard', fontWeight: '700', wordWrap: 'break-word' }}>{fallReports}개 </div>
                    </div>
                    <div className="Frame8" style={{ width: 100, padding: 16, background: '#FFFFFF', borderRadius: 16, border: '1px #CADFFF solid', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', gap: 4, display: 'inline-flex', marginLeft: 20 }}>
                        <div className="Frame6" style={{ justifyContent: 'center', alignItems: 'center', gap: 5, display: 'inline-flex' }}>
                            <div style={{ color: 'black', fontSize: 12, fontFamily: 'Pretendard', fontWeight: '400', wordWrap: 'break-word' }}>간호사호출률</div>
                        </div>
                        <div style={{ color: 'black', fontSize: 24, fontFamily: 'Pretendard', fontWeight: '700', wordWrap: 'break-word' }}>{nurseCallsMetrics}번</div>
                    </div>
        </div>
    );
}

export default QI;
