import React, { useState, useEffect } from 'react';

function NurseTaskPercent({ nurseTaskDataDashboardOne }) {
    const [completionRate, setCompletionRate] = useState(0);
    const { allNewPrescriptions, checkedPrescriptions } = nurseTaskDataDashboardOne;

    useEffect(() => {
        // 확인율 계산 함수
        const calculateCompletionRate = () => {
            if (allNewPrescriptions === 0) {
                setCompletionRate(100);
            } else {
                const rate = (checkedPrescriptions / allNewPrescriptions) * 100;
                setCompletionRate(rate > 100 ? 100 : rate);
            }
        };

        calculateCompletionRate();
    }, [allNewPrescriptions, checkedPrescriptions]);
    //${completionRate.toFixed(0)}%
    return (
                <div className="completion-rate-container" style={{ position: 'relative', display: 'flex' }}>
                    <div className="completion-rate-bar" style={{
                        width: 100,
                        backgroundColor: '#EEF5FF',
                        borderRadius: '4px',
                        height: '8px',
                        position: 'relative'
                    }}>
                        <div className="completion-rate" style={{
                            width: `${completionRate}%`,
                            backgroundColor: '#8CBBFF',
                            height: '100%',
                            borderRadius: '4px',
                            position: 'relative' // 여기에 position relative 추가
                        }}/>
                    </div>
                    <div style={{ 
                        height: '100%', 
                        marginLeft: 32,
                        color: '#333333',
                        fontSize: 12,
                        fontFamily: 'Pretendard',
                        fontWeight: '500',
                        wordWrap: 'break-word',
                        textAlign: 'right',}}
                        >
                            {checkedPrescriptions}/{allNewPrescriptions}
                        </div>
                </div>
          
    );
}

export default NurseTaskPercent;