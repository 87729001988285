import React from 'react';
import { PieChart } from 'react-minimal-pie-chart';

function WardWorkload() {
    const vitalsign_value = (Math.floor(Math.random() * 30) + 1);
    const input_output_value = (Math.floor(Math.random() * 30) + 1);
    const medicatiobn_value = (Math.floor(Math.random() * 30) + 1);
    // 예시 데이터
    const dataMock = [
        { title: 'Vital Sign', value: vitalsign_value, color: '#5DD27A' },
        { title: 'Input & Output', value: input_output_value, color: '#FFAA33' },
        { title: 'Medication', value: medicatiobn_value, color: '#76ADFF' },
    ];

    // 총 개수 계산
    const totalCount = dataMock.reduce((acc, data) => acc + data.value, 0);

    // 데이터 배열에 퍼센테이지 추가
    const dataWithPercentage = dataMock.map(data => ({
        ...data,
        percentage: (data.value / totalCount * 100).toFixed(1) // 소수 첫째자리까지 퍼센테이지 계산
    }));

    return (
        <div style={{
            display: 'flex',        // flexbox 레이아웃 적용
            flexDirection: 'column', // 자식 요소들을 수직 방향으로 쌓음
            justifyContent: 'center', // 수직 방향 중앙 정렬
            alignItems: 'center',    // 가로 방향 중앙 정렬
            height: '100%',
            width: 264
        }}>
            <div style={{ left: 84, top: 12, position: 'absolute', width: '96px', height: '96px' }}>
                <PieChart
                    data={dataWithPercentage}
                    style={{ width: '100%', height: '100%' }}
                    lineWidth={24}
                    paddingAngle={24}
                    rounded
                    totalValue={totalCount}
                    animate
                />
                <div style={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    textAlign: 'center',
                    fontSize: '24px',
                    fontWeight: '700',
                }}>
                    {totalCount}
                </div>
            </div>
            <div className="Frame25" style={{ left: 0, top: 137, width: 264, display: 'flex', flexDirection: 'column', alignItems: 'center', position: 'absolute' }}>
                <div className="Frame22" style={{ alignSelf: 'stretch', justifyContent: 'space-between', alignItems: 'flex-start', display: 'inline-flex' }}>
                    <div className="Frame19" style={{ justifyContent: 'flex-start', alignItems: 'center', gap: 4, display: 'flex' }}>
                        <div className="Rectangle95" style={{ width: 8, height: 8, background: '#77D990', borderRadius: 2 }} />
                        <div className="VitalSign" style={{ color: '#333333', fontSize: 10, fontFamily: 'Pretendard', fontWeight: '400', wordWrap: 'break-word' }}>Vital Sign</div>
                    </div>
                    <div style={{ color: '#333333', fontSize: 10, fontFamily: 'Pretendard', fontWeight: '400', wordWrap: 'break-word' }}>{dataWithPercentage.find(item => item.title === 'Vital Sign').percentage}%</div>
                </div>
                <div className="Vector9" style={{ alignSelf: 'stretch', height: 0, border: '0.50px #EEEEEE solid' }}></div>
                <div className="Frame23" style={{ alignSelf: 'stretch', justifyContent: 'space-between', alignItems: 'flex-start', display: 'inline-flex' }}>
                    <div className="Frame20" style={{ justifyContent: 'flex-start', alignItems: 'center', gap: 4, display: 'flex' }}>
                        <div className="Rectangle96" style={{ width: 8, height: 8, background: '#FFB854', borderRadius: 2 }} />
                        <div className="InputOutput" style={{ color: '#333333', fontSize: 10, fontFamily: 'Pretendard', fontWeight: '400', wordWrap: 'break-word' }}>Input & Output</div>
                    </div>
                    <div style={{ color: '#333333', fontSize: 10, fontFamily: 'Pretendard', fontWeight: '400', wordWrap: 'break-word' }}>{dataWithPercentage.find(item => item.title === 'Input & Output').percentage}%</div>
                </div>
                <div className="Vector10" style={{ alignSelf: 'stretch', height: 0, border: '0.50px #EEEEEE solid' }}></div>
                <div className="Frame24" style={{ alignSelf: 'stretch', justifyContent: 'space-between', alignItems: 'flex-start', display: 'inline-flex' }}>
                    <div className="Frame21" style={{ justifyContent: 'flex-start', alignItems: 'center', gap: 4, display: 'flex' }}>
                        <div className="Rectangle97" style={{ width: 8, height: 8, background: '#A0DBE5', borderRadius: 2 }} />
                        <div className="Medication" style={{ color: '#333333', fontSize: 10, fontFamily: 'Pretendard', fontWeight: '400', wordWrap: 'break-word' }}>Medication</div>
                    </div>
                    <div style={{ color: '#333333', fontSize: 10, fontFamily: 'Pretendard', fontWeight: '400', wordWrap: 'break-word' }}>{dataWithPercentage.find(item => item.title === 'Medication').percentage}%</div>
                </div>
            </div>
        </div>
    );
}

export default WardWorkload;
