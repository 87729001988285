import React from 'react';

function PatientTaskPercent({ patientTaskDataDashboardOne }) {
    const { allNewPrescriptions, checkedPrescriptions } = patientTaskDataDashboardOne;

    //${completionRate.toFixed(0)}%
    return (
        <div className="completion-rate-container" style={{
            display: 'flex', // flex 컨테이너로 만듦
            justifyContent: 'center',
            alignItems: 'flex-end', // 세로 방향으로 하단 정렬
            position: 'relative',
            width: 32, // 컨테이너의 너비 설정
            height: 160
        }}>
            <div className="completion-rate-bar" style={{
                width: '16px',
                height: `${allNewPrescriptions * 5}%`,
                backgroundColor: '#EEF5FF',
                borderTopLeftRadius: 99,
                borderTopRightRadius: 99,
                position: 'absolute',
                bottom: 1
            }}>
                <div className="completion-rate" style={{
                    width: `16px`,
                    height: `${checkedPrescriptions * 5}%`,
                    backgroundColor: '#8CBBFF',
                    borderTopLeftRadius: 99,
                    borderTopRightRadius: 99,
                    position: 'absolute',
                    bottom: 0
                }} />
            </div>
        </div>


    );
}

export default PatientTaskPercent;