import React, { useState, useEffect } from 'react';

function PrescriptionCheckGraph({ allNewPrescriptions, checkedPrescriptions }) {
    const [completionRate, setCompletionRate] = useState(0);

    useEffect(() => {
        // 처음에만 랜덤한 checkedPrescriptions 값을 설정합니다.
        // 여기서 랜덤 설정을 제거하고, Dashboard에서 전달받은 값을 사용합니다.
    }, [allNewPrescriptions, checkedPrescriptions]);

    useEffect(() => {
        // 확인율 계산 함수
        const calculateCompletionRate = () => {
            if (allNewPrescriptions === 0) {
                setCompletionRate(100);
            } else {
                const rate = (checkedPrescriptions / allNewPrescriptions) * 100;
                setCompletionRate(rate > 100 ? 100 : rate);
            }
        };

        calculateCompletionRate();
    }, [allNewPrescriptions, checkedPrescriptions]);
    //${completionRate.toFixed(0)}%
    return (
        <div style={{ position: 'relative' }}>
            <div style={{
                position: 'absolute',
                color: '#333333',
                fontSize: '48px',
                fontFamily: 'Pretendard',
                fontWeight: '700',
                wordWrap: 'break-word'
            }}>
                {`${allNewPrescriptions.toFixed(0)}개`}
            </div>
            <div className="Rectangle65" style={{
                width: '264px', // px 단위 추가
                height: '16px', // px 단위 추가
                position: 'absolute',
                top: 108,
                background: '#5499FF',
                borderRadius: '100px' // px 단위 추가
            }}>
                <div className="completion-rate-container" style={{ position: 'relative' }}>

                    <div className="completion-rate-bar" style={{
                        width: '100%',
                        backgroundColor: '#EEF5FF',
                        borderRadius: '10px',
                        height: '16px',
                        position: 'relative'
                    }}>
                        <div className="completion-rate" style={{
                            width: `${completionRate}%`,
                            backgroundColor: '#5499FF',
                            height: '100%',
                            borderRadius: '10px',
                            position: 'relative' // 여기에 position relative 추가
                        }}>
                            <div className="Frame1" style={{
                                position: 'absolute',
                                top: '-26px', // 위로 26px
                                right: '-20px', // 오른쪽 끝에 맞춤
                                paddingLeft: 8,
                                paddingRight: 8,
                                paddingTop: 4,
                                paddingBottom: 4,
                                background: 'white',
                                borderRadius: 100,
                                border: '1px solid #F1F1F1',
                                justifyContent: 'center',
                                alignItems: 'center',
                                display: 'flex'
                            }}>
                                <div style={{
                                    color: '#333333',
                                    zIndex: 1,
                                    fontSize: 12, // 폰트 사이즈는 원하는 대로 조정
                                    fontFamily: 'Pretendard',
                                    fontWeight: '600',
                                    wordWrap: 'break-word',
                                }}>
                                    {`${completionRate.toFixed(0)}%`}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="completion-rate-text" style={{
                        marginTop: 5,
                        color: '#333333',
                        fontSize: 14,
                        fontFamily: 'Pretendard',
                        fontWeight: '400',
                        wordWrap: 'break-word',
                        textAlign: 'left' // 왼쪽 정렬
                    }}>
                        {`확인율`}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default PrescriptionCheckGraph;