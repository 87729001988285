import React, { useState, useEffect } from 'react';
import { usePatientData } from './use_firestore_data';
import { useDashboardData } from './use_firestore_data';
import DashboardSidebar from './DashboardSidebar';
import DashboardTopbar from './DashboardTopbar';
import Dashboard from './Dashboard1';
import Taskmanagement from './Taskmanagement';
import Wardmanagement from './Wardmanagement';
import { collection, query, where, getDocs } from 'firebase/firestore';
import { dbService } from './firebase';

function Adminpage({
    onLogout,
    allNewPrescriptions,
    checkedPrescriptions,
    patientTaskDataDashboard,
    nurseTaskDataDashboard,
    taskDataTaskManagement
}) {
    const [activePage, setActivePage] = useState('dashboard');

    const patients = usePatientData();
    const dashboardData = useDashboardData();

    const [nurseNames, setNurseNames] = useState([]);
    useEffect(() => {
        // 파이어스토어에서 간호사 이름을 가져오는 함수
        const fetchNurseNames = async () => {
            const nurseCollectionRef = collection(dbService, 'NurSync.Nurses');
            const q = query(nurseCollectionRef, where('name', '!=', null)); // 이름 필드가 null이 아닌 문서만 조회
            const querySnapshot = await getDocs(q);
            const names = querySnapshot.docs.map(doc => doc.data().name); // 모든 문서에서 'name' 필드의 값 추출
            setNurseNames(names);
        };

        fetchNurseNames();
    }, []);

    const showDashboard = () => setActivePage('dashboard');
    const showTaskManagement = () => setActivePage('taskManagement');
    const showWardManagement = () => setActivePage('wardManagement');

    const renderActivePage = () => {
        switch (activePage) {
            case 'taskManagement':
                return <Taskmanagement
                    patients={patients}
                    nurseNames={nurseNames}
                    nurseTaskDataDashboard={nurseTaskDataDashboard}
                    taskDataTaskManagement={taskDataTaskManagement}
                />;
            case 'wardManagement':
                return <Wardmanagement
                    patients={patients}
                    dashboardData = {dashboardData}
                />;
            case 'dashboard':
            default:
                return <Dashboard
                    nurseNames={nurseNames}
                    patients={patients}
                    dashboardData={dashboardData}
                    allNewPrescriptions={allNewPrescriptions}
                    checkedPrescriptions_loading={checkedPrescriptions}
                    patientTaskDataDashboard={patientTaskDataDashboard}
                    nurseTaskDataDashboard={nurseTaskDataDashboard}
                />;
        }
    };

    return (
        <div className="DashboardHome01" style={{
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            height: '100%',
            position: 'relative',
            background: '#FFFFFF',
            borderRadius: 36,
            overflow: 'hidden'
        }}>
            <DashboardTopbar dashboardData={dashboardData} />
            <div style={{
                display: 'flex',
                flex: 1,
                width: 'calc(100% - 280px)',
                marginLeft: '280px',
            }}>
                <DashboardSidebar
                    onLogout={onLogout}
                    showDashboard={showDashboard}
                    showTaskManagement={showTaskManagement}
                    showWardManagement={showWardManagement}
                />
                <div className='content' style={{
                    flex: 1,
                    padding: '1rem',
                    overflowY: 'auto',
                    display: 'flex',
                    flexDirection: 'column'
                }}>
                    {renderActivePage()}
                </div>
            </div>
        </div>
    );
}

export default Adminpage;
