import React from 'react';
import PatientList from './ward_management/patient_list';
import QIList from './ward_management/QI_list';
import { useWindowHeight } from './userwindow'
//import { useWindowWidth } from './userwindow'

function Wardmanagement({ patients, dashboardData }) {
    //const width = useWindowWidth();
    const height = useWindowHeight();
    return (
        <div style={{
            flex: 1,
            background: '#ffffff',
        }}>
            <div style={{ left: 334, top: 40, position: 'absolute', color: 'black', fontSize: 24, fontFamily: 'Pretendard', fontWeight: '600', wordWrap: 'break-word' }}>병동 관리</div>
            <div className="Frame" style={{ width: '100%', height: height - 100, position: 'absolute', left: 280, top: 100 }}>
                <div style={{ width: 504, height: 708, position: 'absolute', left: 64, top: 32, background: '#FCFCFC', borderRadius: 16, overflow: 'hidden' }}>
                    <div style={{ left: 32, top: 24, position: 'absolute', color: '#666666', fontSize: 16, fontFamily: 'Pretendard', fontWeight: '600', wordWrap: 'break-word' }}>환자 목록</div>
                    <div style={{ width: 440, height: 634, position: 'absolute', left: 32, top: 64 }}>
                        <div className="Vector8" style={{ width: 440, height: 0, left: 0, top: 0, position: 'absolute', border: '0.50px #EEEEEE solid' }}></div>
                        <div style={{ width: '100%', height: 20, left: 0, top: 0, position: 'absolute' }}>
                            <div style={{ left: 0, top: 4, position: 'absolute', color: '#666666', fontSize: 10, fontFamily: 'Pretendard', fontWeight: '400', wordWrap: 'break-word' }}>등록번호</div>
                            <div style={{ left: 72, top: 4, position: 'absolute', color: '#666666', fontSize: 10, fontFamily: 'Pretendard', fontWeight: '400', wordWrap: 'break-word' }}>이름</div>
                            <div style={{ left: 122 , top: 4, position: 'absolute', color: '#666666', fontSize: 10, fontFamily: 'Pretendard', fontWeight: '400', wordWrap: 'break-word' }}>성/나이</div>
                            <div style={{ left: 176, top: 4, position: 'absolute', color: '#666666', fontSize: 10, fontFamily: 'Pretendard', fontWeight: '400', wordWrap: 'break-word' }}>생년월일</div>
                            <div style={{ left: 265, top: 4, position: 'absolute', color: '#666666', fontSize: 10, fontFamily: 'Pretendard', fontWeight: '400', wordWrap: 'break-word' }}>병동/병실</div>
                            <div className="Hod" style={{ left: 335, top: 4, position: 'absolute', color: '#666666', fontSize: 10, fontFamily: 'Pretendard', fontWeight: '400', wordWrap: 'break-word' }}>HOD</div>
                            <div className="Pod" style={{ left: 381, top: 4, position: 'absolute', color: '#666666', fontSize: 10, fontFamily: 'Pretendard', fontWeight: '400', wordWrap: 'break-word' }}>POD</div>
                        </div>
                        <div className="Vector9" style={{ width: 440, height: 0, left: 0, top: 20, position: 'absolute', border: '0.50px #EEEEEE solid' }}></div>
                        <div style={{width: 440, height: 614, left: 0, top: 20, position: 'absolute'}}>
                            <PatientList patients={patients}/>
                        </div>
                    </div>
                </div>
                <div style={{ width: 504, height: 708, position: 'absolute', left: 592, top: 32 }}>
                    <QIList dashboardData={dashboardData}/>
                </div>
            </div>
        </div>
    );
}


export default Wardmanagement;
