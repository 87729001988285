import React, {useState, useEffect, useMemo} from 'react';
//import { useWindowWidth } from './userwindow';
import '../css/adminpage.css';
import alert from '../assets/alert1.png'

function DashboardTopbar({dashboardData}) { // 구조 분해 할당을 사용하여 props에서 onLoginSuccess 추출
    //const width = useWindowWidth();
    const currentDate = new Date();
    const dateString = `${currentDate.getFullYear()}년 ${currentDate.getMonth() + 1}월 ${currentDate.getDate()}일`;

    const [currentMetric, setCurrentMetric] = useState('');
    const [infoIndex, setInfoIndex] = useState(0);

    const dashboardItem = dashboardData[0] || {};

    const {
        painRate = 0, // 기본값 설정
        reaskedRate = 0,
        bradenReports = 0,
        nurseCallsMetrics = 0,
        fallReports = 0,
        syringeHurtReports = 0,
    } = dashboardItem;
    
    const metrics = useMemo(() => [
        `환자통증평가율: ${painRate}%`,
        `타과 진료의뢰 회신율: ${reaskedRate}회`,
        `주사침자상사고보고: ${bradenReports}회`,
        `간호사호출빈도: ${nurseCallsMetrics}회`,
        `낙상발생보고: ${fallReports}회`,
        `욕창발생보고: ${syringeHurtReports}%`
    ], [painRate, reaskedRate, bradenReports, nurseCallsMetrics, fallReports, syringeHurtReports]);

    useEffect(() => {
        const updateMetric = () => {
            const nextIndex = (infoIndex + 1) % metrics.length;
            setCurrentMetric(metrics[nextIndex]);
            setInfoIndex(nextIndex);
        };

        const intervalId = setInterval(updateMetric, 10000);

        return () => {
            clearInterval(intervalId);
        };
    }, [infoIndex, metrics]);

    return (
        <div className="Rectangle56" style={{ width: '100%', height: 100, top: 0, right: 0, background: '#FCFCFC', borderBottom: '2px rgba(0, 0, 0, 0.02) solid', textAlign: 'center', position: 'fixed' }}>
            <div className="Group13" style={{ width: 200, height: 36, left: 483, top: 42, position: 'absolute', display: 'flex', alignItems: 'center' }}>
                <div style={{ position: 'absolute', color: '#C0C0C0', fontSize: 14, fontFamily: 'Pretendard', fontWeight: '600', wordWrap: 'break-word' }}>
                    {dateString}
                </div>
            </div>
            <div className="Frame124" style={{ right: 64, top: 28, position: 'absolute', width: 328, height: 40, background: '#ffffff', boxShadow: '0px 1.5px 1.5px rgba(0, 0, 0, 0.02)', borderRadius: 100, justifyContent: 'flex-start', alignItems: 'center', display: 'inline-flex' }}>
                <div className="Group52" style={{ width: 32, height: 32, position: 'absolute', left: 4, top: 4 }}>
                    <img src={alert} alt='alert' style={{ width: 32, height: 32 }} />
                </div>
                <div style={{width: 280, height: 18, position: 'absolute', left: 48, top: 12}}>
                    <div className='text' style={{ left: 0, top: 0, position: 'absolute', height: 18, color: '#999999', fontSize: 12, fontFamily: 'Pretendard', fontWeight: '500', wordWrap: 'break-word' }}>{currentMetric}</div>
                </div>
            </div>
        </div>
    );
}

export default DashboardTopbar;
