// TaskFocusGraph.js
import React from 'react';

function TaskFocusGraph({ taskData, isTopThree }) {
    if (!taskData) {
        return null; // 오류 상태 표시
    }

    const { allNewPrescriptions } = taskData;
    const backgroundColor = isTopThree ? '#5499FF' : '#CADFFF'; // 상위 3개 요소에 대한 배경색 변경

    return (
        <div className="completion-rate-container" style={{
            display: 'flex', // flex 컨테이너로 만듦
            width: '100%', // 컨테이너의 너비 설정
            height: '100%',
            position: 'relative', // 수정: 상위 요소와의 상대적 위치
        }}>
            <div className="completion-rate-bar" style={{
                width: `${allNewPrescriptions * 2}%`,
                height: 8,
                background: backgroundColor,
                borderRadius: '4px',
                position: 'absolute',
                top: -1
            }} >
                {isTopThree && (
                <div style={{
                    position: 'absolute',
                    right: -33, // 오른쪽 정렬을 위해 위치를 조정합니다.
                    color: '#000000',
                    fontSize: 10,
                    fontFamily: 'Pretendard',
                    fontWeight: '600',
                    padding: '0 5px' // 텍스트와 바의 패딩
                }}>
                    {allNewPrescriptions}개
                </div>
            )}
            </div>
        </div>
    );
}

export default TaskFocusGraph;
