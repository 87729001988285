import React, { useState } from 'react';
import { useWindowHeight } from './userwindow';
//import { useWindowWidth } from './userwindow';
import '../css/adminpage.css';
import active_home from '../assets/active_home.png';
import inactive_home from '../assets/inactive_home.png';
import active_task from '../assets/active_task.png';
import inactive_task from '../assets/inactive_task.png';
import active_ward from '../assets/active_ward.png';
import inactive_ward from '../assets/inactive_ward.png';
import logout from '../assets/logout.png';

function DashboardSidebar({ onLogout, showDashboard, showTaskManagement, showWardManagement }) { // 구조 분해 할당을 사용하여 props에서 onLoginSuccess 추출
    //const width = useWindowWidth();
    const height = useWindowHeight();

    const [home_active, setHomeActive] = useState(true);
    const [task_active, setTaskActive] = useState(false);
    const [ward_active, setWardActive] = useState(false);

    const handleDashboardClick = () => {
        setHomeActive(true);
        setTaskActive(false);
        setWardActive(false);
        showDashboard();
    };

    const handleTaskManagementClick = () => {
        setHomeActive(false);
        setTaskActive(true);
        setWardActive(false);
        showTaskManagement();
    };

    const handleWardManagementClick = () => {
        setHomeActive(false);
        setTaskActive(false);
        setWardActive(true);
        showWardManagement();
    };

    // 로그아웃 버튼을 클릭할 때 실행할 함수
    const handleLogout = () => {
        // 부모 컴포넌트로 로그아웃 상태를 전파합니다.
        // 부모 컴포넌트로부터 받은 onLogout 함수를 호출
        if (onLogout) {
            onLogout();
        }
    };

    return (

        <div className="Rectangle21" style={{ zIndex: 1, width: 280, height: height, left: 0, top: 0,  background: '#FAFAFA', borderRight: '2px rgba(0, 0, 0, 0.02) solid', position: 'fixed' }} >
            <div className="Nursync" style={{ marginLeft: 92, marginTop: 52, position: 'absolute', color: '#5499FF', fontSize: 24, fontFamily: 'Pretendard', fontWeight: '600', wordWrap: 'break-word' }}>NurSync</div>
            <div className="SidebarMenu" style={{ width: 232, height: 68, left: 24, top: 128, position: 'absolute' }} onClick={handleDashboardClick}>
                <div className="Rectangle28" style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start', width: 232, height: 68, position: 'absolute', background: '#ffffff', boxShadow: '0px 3.5px 5.5px rgba(0, 0, 0, 0.02)', borderRadius: 20 }}>
                    <div style={{ width: 60, height: 21, left: 72, top: 24, position: 'absolute',textAlign: 'start', color: 'black', fontSize: 14, fontFamily: 'Pretendard', fontWeight: '600', wordWrap: 'break-word' }}>대시보드</div>
                    <div style={{ alignItems: 'center' }}>
                        <img src={home_active ? active_home : inactive_home} alt={home_active ? 'active_home' : 'inactive_home'} style={{ width: 36, height: 36, position: 'absolute', left: 24, top: 16 }} />
                    </div>
                </div>
            </div>

            <div className="SidebarMenu" style={{ width: 232, height: 68, left: 24, top: 220, position: 'absolute' }} onClick={handleTaskManagementClick}>
                <div className="Rectangle28" style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start', width: 232, height: 68, position: 'absolute', background: '#ffffff', boxShadow: '0px 3.5px 5.5px rgba(0, 0, 0, 0.02)', borderRadius: 20 }}>
                    <div style={{ width: 60, height: 21, left: 72, top: 24, position: 'absolute',textAlign: 'start', color: 'black', fontSize: 14, fontFamily: 'Pretendard', fontWeight: '600', wordWrap: 'break-word' }}>업무 관리</div>
                    <div style={{ alignItems: 'center', justifySelf: 'center' }}>
                        <img src={task_active ? active_task : inactive_task} alt={task_active ? 'active_task' : 'inactive_task'} style={{ width: 36, height: 36, position: 'absolute', left: 24, top: 16 }} />
                    </div>
                </div>
            </div>

            <div className="SidebarMenu" style={{ width: 232, height: 68, left: 24, top: 312, position: 'absolute' }} onClick={handleWardManagementClick}>
                <div className="Rectangle28" style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start', width: 232, height: 68, position: 'absolute', background: '#ffffff', boxShadow: '0px 3.5px 5.5px rgba(0, 0, 0, 0.02)', borderRadius: 20 }}>
                    <div style={{ position: 'absolute', marginLeft: 72, color: 'black', fontSize: 14, fontFamily: 'Pretendard', fontWeight: '600', wordWrap: 'break-word' }}>병동 관리</div>
                    <div style={{ alignItems: 'center' }}>
                        <img src={ward_active ? active_ward : inactive_ward} alt={ward_active ? 'active_ward' : 'inactive_ward'} style={{ width: 36, height: 36, position: 'absolute', left: 24, top: 16 }} />
                    </div>
                </div>
            </div>

            <div className="SidebarMenu" style={{ width: 232, height: 68, left: 24, bottom: 64, position: 'absolute' }} onClick={handleLogout}>
                <div className="Rectangle28" style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start', width: 232, height: 68, position: 'absolute', background: '#ffffff', boxShadow: '0px 3.5px 5.5px rgba(0, 0, 0, 0.02)', borderRadius: 20 }}>
                    <div style={{ width: 60, height: 21, left: 72, top: 24, position: 'absolute',textAlign: 'start', color: 'black', fontSize: 14, fontFamily: 'Pretendard', fontWeight: '600', wordWrap: 'break-word' }}>로그아웃</div>
                    <div style={{ alignItems: 'center' }}>
                        <img src={logout} alt={'logout'} style={{ marginLeft: 24, width: 36, height: 36 }} />
                    </div>
                </div>
            </div>

        </div>

    );
}

export default DashboardSidebar;
