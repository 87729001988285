import { useEffect, useState } from 'react';
import { collection, query, onSnapshot, orderBy, where } from 'firebase/firestore';
import { dbService } from './firebase';

// 나이 계산 함수
function calculateAge(birthdate) {
  const today = new Date();
  const birthDate = new Date(birthdate);
  let age = today.getFullYear() - birthDate.getFullYear();
  const m = today.getMonth() - birthDate.getMonth();
  if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
    age--;
  }
  return age;
}

function usePatientData() {
  const [patients, setPatients] = useState([]);

  useEffect(() => {
    const patientsRef = collection(dbService, 'Patients');
    // id로 정렬해서 가져오기
    const q = query(patientsRef, orderBy('id'));

    const unsubscribe = onSnapshot(q, (snapshot) => {
      const patientData = snapshot.docs.map(doc => {
        const data = doc.data();
        return {
          id: data.id,
          name: data.name,
          sex: data.sex,
          age: calculateAge(data.birthdate),
          birthdate: data.birthdate,
          ward: data.ward,
          hod: data.hod,
          pod: data.pod
        };
      });
      setPatients(patientData);
    });

    return () => unsubscribe();
  }, []);

  return patients;
}

function useDashboardData() {
  const [dashboardData, setDashboardData] = useState([]);

  useEffect(() => {
    const today = new Date();
    const dateString = today.toISOString().split('T')[0].replaceAll('-', '.');

    const dashboardDRef = collection(dbService, 'DashboardWardMetrics');
    // date가 오늘인 문서만 가져오기
    const q = query(
      dashboardDRef,
      where('date', '==', dateString)
    );

    const unsubscribe = onSnapshot(q, (snapshot) => {
      const dashboardData = snapshot.docs.map(doc => {
        const data = doc.data();
        return {
          bradenReports: data.bradenReports, //욕창
          codeBlues: data.codeBlues,
          codeReds: data.codeReds,
          codeYellows: data.codeYellows,
          date: data.date,
          fallReports: data.fallReports,
          nurseCallsMetrics: data.nurseCallsMetrics,
          painRate: data.painRate,
          reaskedRate: data.reaskedRate,
          syringeHurtReports: data.syringeHurtReports
        };
      });
      setDashboardData(dashboardData);
    });

    return () => unsubscribe(); 
  }, []);

  return dashboardData;
}

export { usePatientData, useDashboardData };
