import React from 'react';

function Top3TaskFocus({ topThreeData }) {
    // 상위 3개의 데이터로 카드를 생성합니다.
    const topThreeCards = topThreeData.map((data, index) => {
        const indexStr = String(data.index).padStart(2, '0');
        const nextHourStr = String((data.index + 1) % 24).padStart(2, '0');
        return (
            <div key={index} style={{
                width: 83,
                height: 57,
                position: 'relative',
                background: '#ffffff',
                border: '1px rgba(0, 0, 0, 0.05) solid',
                borderRadius: 8,
                marginRight: 8,
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
            }}>
                <div className="Ellipse17" style={{ width: 12, height: 12, left: 8, top: 8, position: 'absolute', background: '#5499FF', borderRadius: 9999 }} />
                <div style={{ left: 12, top: 9, position: 'absolute', color: '#ffffff', fontSize: 8, fontFamily: 'Pretendard', fontWeight: '600', wordWrap: 'break-word' }}>{index + 1}</div>
                <div style={{ left: 20, top: 23, position: 'absolute', color: '#333333', fontSize: 20, fontFamily: 'Pretendard', fontWeight: '600', wordWrap: 'break-word' }}>
                    {data.allNewPrescriptions}개
                </div>
                <div style={{ left: 24, top: 9, position: 'absolute', color: '#B0D0FF', fontSize: 8, fontFamily: 'Pretendard', fontWeight: '400', wordWrap: 'break-word' }}>
                    {indexStr}:00 ~ {nextHourStr}:00
                </div>
            </div>
        );
    });

    return (
        <div style={{
            display: 'flex',
            flexDirection: 'row', // 카드를 가로로 배치합니다.
            alignItems: 'center',
        }}>
            {topThreeCards}
        </div>
    );
}

export default Top3TaskFocus;
