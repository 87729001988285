import React from 'react';
import TaskFocusGraph from './chart/task_focus_graph';
import Top3TaskFocus from './chart/top3_task_focus';

function TaskFocus({ taskDataTaskManagement }) {
    if (!Array.isArray(taskDataTaskManagement)) {
        return null; // 또는 로딩 상태 표시
    }

    // allNewPrescriptions 기준으로 내림차순 정렬 복사본 생성
    const sortedTaskData = [...taskDataTaskManagement].sort((a, b) => b.allNewPrescriptions - a.allNewPrescriptions);
    // 상위 3개 값을 가지는 요소의 allNewPrescriptions 값 추출
    const topThreeValues = sortedTaskData.slice(0, 3).map(data => data.allNewPrescriptions);
    const topThreeData = sortedTaskData.slice(0, 3).map((data, index) => ({
        ...data,
        index: index + 1 // 실제 인덱스가 아닌 1부터 시작하는 순위를 나타내기 위해 사용
    }));

    return (
        <div style={{ width: '100%', height: '100%' }}>
            <div className="Top3" style={{ left: 0, top: 0, position: 'absolute', color: '#333333', fontSize: 12, fontFamily: 'Pretendard', fontWeight: '600', wordWrap: 'break-word' }}>TOP 3</div>
            <div style={{ width: '100%', height: 57, position: 'absolute', left: 0, top: 22 }}>
                <Top3TaskFocus topThreeData={topThreeData}/>
            </div>
            <div style={{ width: 290, height: 378, position: 'absolute', left: 0, top: 107, overflowY: 'auto', overflowX: 'hidden' }}>
                {taskDataTaskManagement.map((taskData, index) => {
                    const indexStr = index.toString().padStart(2, '0');
                    return (
                        <div key={index} style={{
                            width: 264,
                            height: 10,
                            display: 'flex',
                            alignItems: 'center',
                            position: 'relative', // 'absolute'에서 'relative'로 변경
                            marginBottom: '5px', // 아이템 간격 조정
                        }}>
                            <div style={{
                                width: 15,
                                height: 10,
                                justifyContent: 'center',
                                color: '#AAAAAA',
                                fontSize: 10,
                                fontFamily: 'Pretendard',
                                fontWeight: '400',
                                wordWrap: 'break-word'
                            }}>
                                {indexStr}
                            </div>
                            <div style={{ marginLeft: 23, width: 241, height: 10 }}>
                                <TaskFocusGraph taskData={taskData} isTopThree={topThreeValues.includes(taskData.allNewPrescriptions)} />
                            </div>
                        </div>
                    );
                })}
            </div>
        </div>
    );
}

export default TaskFocus;
