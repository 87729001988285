import React, { useState } from 'react';
import { useWindowWidth, useWindowHeight } from './js/userwindow';
import LoginScreen from './js/logIn_screen';
import Adminpage from './js/admin_page';
import './css/App.css';
import './css/adminpage.css';
import { Amplify } from 'aws-amplify';
import awsmobile from './aws-exports';

Amplify.configure(awsmobile);

function App() {
  const width = useWindowWidth();
  const height = useWindowHeight();
  const [isLoggedIn, setIsLoggedIn] = useState(false); // 로그인 상태 관리

  const [allNewPrescriptions, setAllNewPrescriptions] = useState(0);
  const [checkedPrescriptions, setCheckedPrescriptions] = useState(0);

  const [patientTaskDataDashboard, setPatientTaskDataDashboard] = useState([]);
  const [nurseTaskDataDashboard, setNurseTaskDataDashboard] = useState([]);
  const [taskDataTaskManagement, setTaskDataTaskManagement] = useState([]);

  const handleLogout = () => {
    setIsLoggedIn(false);
  };

  const handleLoginSuccess = () => {
    // 로그인 성공 시 무작위 값 생성 및 상태 업데이트
    const newPrescriptions = Math.floor(Math.random() * 60) + 1;
    const checked = Math.floor(Math.random() * newPrescriptions) + 1;
    setAllNewPrescriptions(newPrescriptions);
    setCheckedPrescriptions(checked);
    // 로그인 성공 시 13개의 환자별 업무량 데이터 묶음을 생성합니다.
    const patienttaskdataDashboard = Array.from({ length: 13 }, () => {
      const allNewPrescriptions = Math.floor(Math.random() * 5) + 15;
      return {
        allNewPrescriptions: allNewPrescriptions,
        checkedPrescriptions: Math.floor(Math.random() * allNewPrescriptions) + 1
      };
    });
    setPatientTaskDataDashboard(patienttaskdataDashboard);
    // 로그인 성공 시 11개의 간호사 업무량 데이터 묶음을 생성합니다.
    const nursetaskdataDashboard = Array.from({ length: 11 }, () => {
      const allNewPrescriptions = Math.floor(Math.random() * 10) + 15;
      return {
        allNewPrescriptions: allNewPrescriptions,
        checkedPrescriptions: Math.floor(Math.random() * allNewPrescriptions + 1)
      };
    });
    setNurseTaskDataDashboard(nursetaskdataDashboard);
    // 로그인 성공 시 24개의 업무량 데이터 묶음을 생성합니다.
    const taskDatataskmanagement = Array.from({ length: 31 }, () => {
      const allNewPrescriptions = Math.floor(Math.random() * 40) + 5;
      return {
        allNewPrescriptions: allNewPrescriptions,
      };
    });
    setTaskDataTaskManagement(taskDatataskmanagement);

    // 로그인 상태 업데이트
    setIsLoggedIn(true);
  };

  return (
    <div className="App">
      <div className="NurSyncWeb" style={{ width: width, height: height }}>
        {!isLoggedIn ? (
          <LoginScreen onLoginSuccess={handleLoginSuccess} />
        ) : (
          <Adminpage
            onLogout={handleLogout}
            allNewPrescriptions={allNewPrescriptions}
            checkedPrescriptions={checkedPrescriptions}
            patientTaskDataDashboard = {patientTaskDataDashboard}
            nurseTaskDataDashboard = {nurseTaskDataDashboard}
            taskDataTaskManagement = {taskDataTaskManagement}
          />
        )}
      </div>
    </div>
  );
}

export default App;

