import React from 'react';
import { useWindowHeight } from './userwindow'
import PatientTaskList from './task_manage_page/patient_task_list';
import StatusNurseWork from './chart/status_nurse_work';
import TaskFocus from './task_manage_page/task_focus';
//import { useWindowWidth } from './userwindow'

function Taskmanagement({ patients, nurseNames, nurseTaskDataDashboard, taskDataTaskManagement }) {
    //const width = useWindowWidth();
    const height = useWindowHeight();
    return (
        <div style={{
            flex: 1,
            background: '#ffffff',
        }}>
            <div style={{ left: 334, top: 40, position: 'absolute', color: '#333333', fontSize: 24, fontFamily: 'Pretendard', fontWeight: '600', wordWrap: 'break-word' }}>업무 관리</div>
            <div className="Frame" style={{ width: '100%', height: height - 100, position: 'absolute', left: 280, top: 100 }}>
                <div>
                    <div cla ssName="Frame15" style={{ width: 240, height: 116, left: 64, top: 32, position: 'absolute', background: '#5499FF', borderRadius: 16, overflow: 'hidden' }}>
                        <div className="Day" style={{ left: 32, top: 24, position: 'absolute', color: 'white', fontSize: 16, fontFamily: 'Pretendard', fontWeight: '600', wordWrap: 'break-word' }}>Day</div>
                        <div className="001400" style={{ left: 132, top: 27, position: 'absolute', color: '#F1F1F1', fontSize: 12, fontFamily: 'Pretendard', fontWeight: '400', wordWrap: 'break-word' }}>06:00 ~ 14:00</div>
                        <div style={{ left: 32, top: 56, position: 'absolute', color: 'white', fontSize: 24, fontFamily: 'Pretendard', fontWeight: '700', wordWrap: 'break-word' }}>6명</div>
                    </div>
                    <div className="Frame47" style={{ width: 240, height: 116, left: 328, top: 32, position: 'absolute', background: '#FCFCFC', borderRadius: 16, overflow: 'hidden' }}>
                        <div className="Evening" style={{ left: 32, top: 24, position: 'absolute', color: '#666666', fontSize: 16, fontFamily: 'Pretendard', fontWeight: '600', wordWrap: 'break-word' }}>Evening</div>
                        <div className="001400" style={{ left: 132, top: 27, position: 'absolute', color: '#929292', fontSize: 12, fontFamily: 'Pretendard', fontWeight: '400', wordWrap: 'break-word' }}>14:00 ~ 22:00</div>
                        <div style={{ left: 32, top: 56, position: 'absolute', color: '#333333', fontSize: 24, fontFamily: 'Pretendard', fontWeight: '700', wordWrap: 'break-word' }}>6명</div>
                    </div>
                    <div className="Frame48" style={{ width: 240, height: 116, left: 592, top: 32, position: 'absolute', background: '#FCFCFC', borderRadius: 16, overflow: 'hidden' }}>
                        <div className="Night" style={{ left: 32, top: 24, position: 'absolute', color: '#666666', fontSize: 16, fontFamily: 'Pretendard', fontWeight: '600', wordWrap: 'break-word' }}>Night</div>
                        <div className="001400" style={{ left: 132, top: 27, position: 'absolute', color: '#929292', fontSize: 12, fontFamily: 'Pretendard', fontWeight: '400', wordWrap: 'break-word' }}>22:00 ~ 06:00</div>
                        <div style={{ left: 32, top: 56, position: 'absolute', color: '#333333', fontSize: 24, fontFamily: 'Pretendard', fontWeight: '700', wordWrap: 'break-word' }}>6명</div>
                    </div>
                    <div className="Frame49" style={{ width: 240, height: 116, left: 856, top: 32, position: 'absolute', background: '#FCFCFC', borderRadius: 16, overflow: 'hidden' }}>
                        <div className="Off" style={{ left: 32, top: 24, position: 'absolute', color: '#666666', fontSize: 16, fontFamily: 'Pretendard', fontWeight: '600', wordWrap: 'break-word' }}>Off</div>
                        <div className="001400" style={{ left: 132, top: 27, position: 'absolute', color: '#929292', fontSize: 12, fontFamily: 'Pretendard', fontWeight: '400', wordWrap: 'break-word' }}>00:00 ~ 00:00</div>
                        <div style={{ left: 32, top: 56, position: 'absolute', color: '#333333', fontSize: 24, fontFamily: 'Pretendard', fontWeight: '700', wordWrap: 'break-word' }}>6명</div>
                    </div>
                </div>
                <div className="Frame45" style={{ width: 326, height: 568, left: 64, top: 172, position: 'absolute', background: '#FCFCFC', borderRadius: 16, overflow: 'hidden' }}>
                    <div style={{ left: 32, top: 24, position: 'absolute', color: '#666666', fontSize: 16, fontFamily: 'Pretendard', fontWeight: '600', wordWrap: 'break-word' }}>환자별 업무 현황</div>
                    <div className="Frame87" style={{ width: 262, height: 504, left: 32, top: 64, position: 'absolute', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', gap: 10, display: 'inline-flex' }}>
                        <PatientTaskList
                            patients={patients}
                        />
                    </div>
                </div>
                <div className="Frame46" style={{ width: 328, height: 568, left: 416, top: 172, position: 'absolute', background: '#FCFCFC', borderRadius: 16, overflow: 'hidden' }}>
                    <div style={{ left: 32, top: 24, position: 'absolute', color: '#666666', fontSize: 16, fontFamily: 'Pretendard', fontWeight: '600', wordWrap: 'break-word' }}>업무 집중도</div>
                    <div style={{ left: 32, top: 64, width: 264, height: 485, position: 'absolute' }}>
                        <TaskFocus taskDataTaskManagement={taskDataTaskManagement} />
                    </div>
                </div>
                <div className="CardQi" style={{ width: 328, height: 292, left: 768, top: 171, position: 'absolute', background: '#FCFCFC', borderRadius: 24, overflow: 'hidden' }}>
                    <div style={{ left: 32, top: 24, position: 'absolute', color: '#666666', fontSize: 16, fontFamily: 'Pretendard', fontWeight: '600', wordWrap: 'break-word' }}>간호사별 업무 진행도</div>
                    <div className="Frame44" style={{ right: 48, top: 25, position: 'absolute', width: 25, height: 21, paddingLeft: 8, paddingRight: 8, borderRadius: 100, border: '1px #34C759 solid', justifyContent: 'center', alignItems: 'center', gap: 10, display: 'inline-flex' }}>
                        <div className="Day" style={{ color: '#333333', fontSize: 14, fontFamily: 'Pretendard', fontWeight: '600', wordWrap: 'break-word' }}>Day</div>
                    </div>
                    <div style={{ left: 33, top: 69, position: 'absolute', width: 255, height: 208 }}>
                        <StatusNurseWork nurseNames={nurseNames} nurseTaskDataDashboard={nurseTaskDataDashboard} />
                    </div>
                </div>
                <div className="CardQi" style={{ width: 328, height: 264, left: 768, top: 476, position: 'absolute', background: '#FCFCFC', borderRadius: 16, overflow: 'hidden' }}>
                    <div style={{ left: 32, top: 24, position: 'absolute', color: '#666666', fontSize: 16, fontFamily: 'Pretendard', fontWeight: '600', wordWrap: 'break-word' }}>초과근무 현황</div>
                    <div className="Vector8" style={{ width: 264, height: 0, left: 32, top: 84, position: 'absolute', border: '0.50px #EEEEEE solid' }}></div>
                    <div className="Vector9" style={{ width: 264, height: 0, left: 32, top: 64, position: 'absolute', border: '0.50px #EEEEEE solid' }}></div>
                    <div style={{ left: 32, top: 68, position: 'absolute', color: '#333333', fontSize: 10, fontFamily: 'Pretendard', fontWeight: '400', wordWrap: 'break-word' }}>순위</div>
                    <div style={{ left: 66, top: 68, position: 'absolute', color: '#333333', fontSize: 10, fontFamily: 'Pretendard', fontWeight: '400', wordWrap: 'break-word' }}>이름</div>
                    <div style={{ left: 138, top: 68, position: 'absolute', color: '#333333', fontSize: 10, fontFamily: 'Pretendard', fontWeight: '400', wordWrap: 'break-word' }}>초과근무 시간</div>
                    <div style={{ left: 225, top: 68, position: 'absolute', color: '#333333', fontSize: 10, fontFamily: 'Pretendard', fontWeight: '400', wordWrap: 'break-word' }}>증가/감소</div>
                    <div className="Frame5" style={{ width: 24, height: 24, left: 284, top: 24, position: 'absolute' }}>
                    </div>
                    <div className="Frame57" style={{ left: 32, top: 100, position: 'absolute', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', display: 'inline-flex' }}>
                        <div className="Frame48" style={{ justifyContent: 'start', alignItems: 'center', gap: 21, display: 'inline-flex', marginBottom: 8 }}>
                            <div style={{ width: 16, color: '#666666', fontSize: 12, fontFamily: 'Pretendard', fontWeight: '400', wordWrap: 'break-word' }}>1</div>
                            <div style={{ width: 48, color: '#666666', fontSize: 12, fontFamily: 'Pretendard', fontWeight: '500', wordWrap: 'break-word' }}>강정석</div>
                            <div style={{ width: 67, color: '#333333', fontSize: 12, fontFamily: 'Pretendard', fontWeight: '500', wordWrap: 'break-word' }}>1시간</div>
                            <div className="20" style={{ width: 36, color: '#FF4C4C', fontSize: 10, fontFamily: 'Pretendard', fontWeight: '400', wordWrap: 'break-word' }}>+20분</div>
                        </div>
                        <div className="Frame49" style={{ justifyContent: 'center', alignItems: 'center', gap: 21, display: 'inline-flex', marginBottom: 8 }}>
                            <div style={{ width: 16, color: '#666666', fontSize: 12, fontFamily: 'Pretendard', fontWeight: '400', wordWrap: 'break-word' }}>2</div>
                            <div style={{ width: 48, color: '#666666', fontSize: 12, fontFamily: 'Pretendard', fontWeight: '500', wordWrap: 'break-word' }}>박지혜</div>
                            <div style={{ width: 67, color: '#333333', fontSize: 12, fontFamily: 'Pretendard', fontWeight: '500', wordWrap: 'break-word' }}>40분</div>
                            <div className="20" style={{ width: 36, color: '#5DD27A', fontSize: 10, fontFamily: 'Pretendard', fontWeight: '400', wordWrap: 'break-word' }}>-20분</div>
                        </div>
                        <div className="Frame50" style={{ justifyContent: 'center', alignItems: 'center', gap: 21, display: 'inline-flex', marginBottom: 8 }}>
                            <div style={{ width: 16, color: '#666666', fontSize: 12, fontFamily: 'Pretendard', fontWeight: '400', wordWrap: 'break-word' }}>3</div>
                            <div style={{ width: 48, color: '#666666', fontSize: 12, fontFamily: 'Pretendard', fontWeight: '500', wordWrap: 'break-word' }}>이수민</div>
                            <div style={{ width: 67, color: '#333333', fontSize: 12, fontFamily: 'Pretendard', fontWeight: '500', wordWrap: 'break-word' }}>20분</div>
                            <div className="20" style={{ width: 36, color: '#FF4C4C', fontSize: 10, fontFamily: 'Pretendard', fontWeight: '400', wordWrap: 'break-word' }}>+20분</div>
                        </div>
                        <div className="Frame51" style={{ justifyContent: 'center', alignItems: 'center', gap: 21, display: 'inline-flex', marginBottom: 8 }}>
                            <div style={{ width: 16, color: '#666666', fontSize: 12, fontFamily: 'Pretendard', fontWeight: '400', wordWrap: 'break-word' }}>4</div>
                            <div style={{ width: 48, color: '#666666', fontSize: 12, fontFamily: 'Pretendard', fontWeight: '500', wordWrap: 'break-word' }}>정다은</div>
                            <div style={{ width: 67, color: '#333333', fontSize: 12, fontFamily: 'Pretendard', fontWeight: '500', wordWrap: 'break-word' }}>15분</div>
                            <div className="8" style={{ width: 36, color: '#5DD27A', fontSize: 10, fontFamily: 'Pretendard', fontWeight: '400', wordWrap: 'break-word' }}>-8분</div>
                        </div>
                        <div className="Frame52" style={{ justifyContent: 'center', alignItems: 'center', gap: 21, display: 'inline-flex', marginBottom: 8 }}>
                            <div style={{ width: 16, color: '#666666', fontSize: 12, fontFamily: 'Pretendard', fontWeight: '400', wordWrap: 'break-word' }}>5</div>
                            <div style={{ width: 48, color: '#666666', fontSize: 12, fontFamily: 'Pretendard', fontWeight: '500', wordWrap: 'break-word' }}>송민서</div>
                            <div style={{ width: 67, color: '#333333', fontSize: 12, fontFamily: 'Pretendard', fontWeight: '500', wordWrap: 'break-word' }}>12분</div>
                            <div className="20" style={{ width: 36, color: '#FF4C4C', fontSize: 10, fontFamily: 'Pretendard', fontWeight: '400', wordWrap: 'break-word' }}>+20분</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}


export default Taskmanagement;
